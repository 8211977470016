<template>
    <div class="grid dashboard">
        <div class="col-12 md:col-6 lg:col-3">
            <div class="card overview-box flex flex-column pt-2 cursor-pointer" @click="onInfo">
                <div class="flex align-items-center muted-text">
                    <i class="pi pi-info-circle"></i>
                    <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Produktinformationen</h6>
                    <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">ID {{ $route.params.id }}</h6>
                </div>
                <div class="flex justify-content-between mt-3 flex-wrap">
                    <div class="flex flex-column items-al" style="width: 300px;">
                        <span class="mb-1 fs-xlarge">{{produktObj.GRUPPE}}</span>
                        <span class="overview-status p-1 teal-bgcolor fs-small">{{produktObj.HERSTELLER}} {{produktObj["TYP/MODEL"]}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
            <div class="card overview-box flex flex-column pt-2 cursor-pointer" @click="onStandort">
                <div class="flex align-items-center muted-text">
                    <i class="pi pi-building"></i>
                    <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Standort</h6>
                </div>
                <div class="flex justify-content-between mt-3 flex-wrap">
                    <div class="flex flex-column" style="width: 300px;">
                        <span class="mb-1 fs-xlarge">{{produktObj.STANDORT}}</span>
                        <span class="overview-status p-1 teal-bgcolor fs-small">Zimmer Nr.{{produktObj.ZIMMER_NR}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!--		<div class="col-12 md:col-6 lg:col-3">-->
        <!--			<div class="card overview-box flex flex-column pt-2">-->
        <!--				<div class="flex align-items-center muted-text">-->
        <!--					<i class="pi pi-check-square"></i>-->
        <!--					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Status</h6>-->
        <!--				</div>-->
        <!--				<div class="flex justify-content-between mt-3 flex-wrap">-->
        <!--					<div class="flex flex-column" style="width: 80px;">-->
        <!--						<span class="mb-1 fs-xlarge">{{produktObj.STATUS}}</span>-->
        <!--						<span v-if="produktObj.STATUS === 'n.i.O'" class="overview-status p-1 pink-bgcolor fs-small">Weist Mängel auf!</span>-->
        <!--						<span v-else class="overview-status p-1 teal-bgcolor fs-small">Einwandfrei keine Mängel</span>-->
        <!--          </div>-->
        <!--				</div>-->
        <!--			</div>-->
        <!--		</div>-->
        <div class="col-12 md:col-6 lg:col-3">
            <div class="card overview-box flex flex-column pt-2">
                <div class="flex align-items-center muted-text">
                    <i class="pi pi-qrcode"></i>
                    <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">QR Code</h6>
                </div>
                <div class="mt-3 cursor-pointer" @click="onQr">
                    <div :class="store.getters.getRowClass">
                        <qr-code :size=75 :text=value></qr-code>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-12 lg:col-12">
            <div class="card overview-box flex flex-column pt-2">
                <div class="flex align-items-center muted-text">
                    <i class="pi pi-check-square"></i>
                    <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Status</h6>
                </div>
                <div class="flex align-items-center justify-content-end">
                    <div class="flex justify-content-between mt-0 flex-wrap">
                        <div class="flex flex-column" style="width: 80px;">
                            <span class="mb-1">{{produktObj.STATUS}}</span>
                            <span v-if="produktObj.STATUS === 'n.i.O'" class="overview-status p-1 pink-bgcolor fs-small">Weist Mängel auf!</span>
                            <span v-else class="overview-status p-1 teal-bgcolor fs-small">Einwandfrei keine Mängel</span>
                        </div>
                    </div>
                </div>
                <div class="muted-text">Lebenszyklus</div>
                <div class="flex justify-content-between  mb-2 mt-3">
                    <small class="muted-text">{{this.gebrauchJahr}}/{{this.totalJahr}} Jahren</small>
                </div>
                <ProgressBar :value=this.prozentJahr :showValue="false">test</ProgressBar>
                <div class="flex justify-content-between mb-2">
                    <small class="mb-1 muted-text">{{this.startJahr}}</small>
                    <small class="muted-text">{{this.endJahr}}</small>
                </div>
            </div>
            <div>
                <Accordion>
                    <AccordionTab>
                        <template #header>
                            <div class="flex align-items-center muted-text">
                                <i class="pi pi-eye"></i>
                                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Inspektionen</h6>
                            </div>
                        </template>
                        <InspektionenTable></InspektionenTable>
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <div class="flex align-items-center muted-text">
                                <i class="pi pi-list"></i>
                                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Mängelübersicht</h6>
                            </div>
                        </template>
                        <div class="grid">
                            <div class="col-12 lg:col-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h5>Mängel</h5>
                                    </div>
                                    <ReperaturenTable @reperatur="getReperatur"></ReperaturenTable>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6">
                                <div class="card height-100 widget-timeline">
                                    <div class="card-header">
                                        <h5>Timeline</h5>
                                    </div>
                                    <Timeline :value="timelineEvents" align="left" class="customized-timeline">
                                        <template #marker="slotProps">
						<span class="custom-marker shadow-2 p-2" :style="{backgroundColor: slotProps.item.color}">
							<i class="marker-icon" :class="slotProps.item.icon"></i>
						</span>
                                        </template>
                                        <template #content="slotProps">
                                            <Card class="mb-3">
                                                <template #title>
                                                    {{slotProps.item.status}}
                                                </template>
                                                <template #subtitle>
                                                    {{slotProps.item.date}}
                                                </template>
                                                <template #content>
                                                    <img v-if="slotProps.item.image" :src="'assets/showcase/images/demo/product/' + slotProps.item.image" :alt="slotProps.item.name" width="200" class="shadow-2" />
                                                    <p>{{slotProps.item.description}}</p>
                                                    <p class="font-italic">{{slotProps.item.defect}}</p>
                                                    <p class="font-italic">{{slotProps.item.final}}</p>
                                                </template>
                                            </Card>
                                        </template>
                                    </Timeline>


                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <div class="flex align-items-center muted-text">
                                <i class="pi pi-file-pdf"></i>
                                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Dateien</h6>
                            </div>
                        </template>
                        <Files></Files>
                    </AccordionTab>
                </Accordion>
            </div>

        </div>

        <Dialog v-model:visible="infoDialog" :style="{width: '450px'}" header="Produkt Informationen" :modal="true" class="p-fluid">
            <div class="field">
                <label for="ID">Id</label>
                <InputText id="id" v-model.trim="produktObj.ID"  />
            </div>
            <div class="field">
                <label for="MODELL">Typ/Modell</label>
                <InputText id="modell" v-model.trim="produktObj['TYP/MODEL']"  />
            </div>
            <div class="field">
                <label for="HERSTELLER">Hersteller</label>
                <InputText id="hersteller" v-model.trim="produktObj.HERSTELLER" />
            </div>
            <div class="field">
                <label for="SERIENNUMMER">Seriennummer</label>
                <InputText id="seriennummer" v-model.trim="produktObj.SERIENNUMMER" />
            </div>
            <div class="field">
                <label for="INVENTARNUMMER">Inventarnummer</label>
                <InputText id="inventarnummer" v-model.trim="produktObj.INVENTARNUMMER" />
            </div>
            <div class="field">
                <label for="SCHUTZKLASSE">Schutzklasse</label>
                <InputText id="schutzklasse" v-model.trim="produktObj.SCHUTZKLASSE" />
            </div>
            <div class="field">
                <label for="KENNZEICHNUNG">Kennzeichnung</label>
                <InputText id="kennzeichnung" v-model.trim="produktObj.KENNZEICHNUNG" />
            </div>
        </Dialog>
        <Dialog v-model:visible="standortDialog" :style="{width: '850px', height: '850px'}" header="Standort Informationen" :modal="true" class="p-fluid">
            <div class="card">
                <ProduktHistoryTable></ProduktHistoryTable>
            </div>
        </Dialog>
        <Dialog v-model:visible="qrDialog" :style="{width: '1000px'}" header="QR Drucketikette" :modal="true" maximizable>
            <Button type="button" label="Drucken" icon="pi pi-print" @click="onPrint" class="mb-3"></button>

            <div class="grid layout-invoice-content flex justify-content-center flex-wrap">
                <div class="p-3 origin-center">
                    <div class="col-12 md:col-12 rotate-90">
                        <div class="card rotate-180">
                            <qr-code :size="700" :text="value"></qr-code>
                            <p class="text-7xl font-bold">ID: {{ $route.params.id }}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 rotate-90 origin-top">
                    <div class="rotate-180">
                        <p class="text-8xl font-bold">Acticom AG</p>
                        <p class="text-7xl">www.acticom.ch</p>
                        <p class="text-6xl">+41 (0)61 733 08 50</p>
                    </div>
                </div>
            </div>

        </Dialog>




    </div>


</template>

<script>
import DataService from "@/service/DataService";
import ReperaturenTable from "@/components/ReperaturenTable";
import moment from "moment";
import ProduktHistoryTable from "@/components/ProduktHistoryTable";
import InspektionenTable from "@/components/InspektionenTable";
import Files from "@/components/Files";
import store from "@/store";
import { environment } from "../../environment/environment";

export default {
    components: {InspektionenTable, ProduktHistoryTable, ReperaturenTable, Files},
    data() {
        return {
            value: '',
            timelineEvents: [],
            produkt: null,
            produktObj: {},
            reperatur: null,
            reperaturObj: {},
            startJahr: null,
            endJahr: null,
            totalJahr: null,
            gebrauchJahr: null,
            prozentJahr: null,
            infoDialog: false,
            standortDialog: false,
            qrDialog:false
        }
    },
    productService: null,
    dataService: null,
    created() {
        this.dataService = new DataService();
    },
    async mounted() {
        this.produkt = await this.dataService.getProdukteById(this.$route.params.id);
        this.produktObj = this.produkt[0];
        console.log(this.$route.fullPath)
        let path = this.$route.fullPath.substring(0, this.$route.fullPath.indexOf('#'));
        console.log(path)
        this.value = path.length >=1 ? `${environment.path}${path}`: `${environment.path}${this.$route.fullPath}`
        this.calculateLebensZyklus();
        this.reperatur = await this.dataService.getReperaturByProuktIdLatest(this.$route.params.id);
        this.reperaturObj = this.reperatur[0];

        this.timelineEvents[0] = { status: 'Offen', date: this.formatDate(this.reperaturObj.TS_EINGANG), icon: "pi pi-shopping-cart", color: '#E91E63', description: this.reperaturObj.EINGANGSMELDUNG, defect: this.reperaturObj.MAENGEL };
        if (this.reperaturObj.ANGENOMMEN){
            this.timelineEvents[1] = { status: 'In Bearbeitung', date: this.formatDate(this.reperaturObj.TS_ANNAHME), icon: "pi pi-cog", color: '#FB8C00', description: this.reperaturObj.ANNAHMEMELDUNG };
        }
        if (this.reperaturObj.ABGESCHLOSSEN){
            this.timelineEvents[2] = { status: 'Abgeschlossen', date: this.formatDate(this.reperaturObj.TS_ABSCHLUSS), icon: "pi pi-check-square", color: '#0097A7', description: this.reperaturObj.ABSCHLUSSMELDUNG, final:this.reperaturObj.ABSCHLUSS };
        }




    },
    methods: {
        formatDate(dateString){
            return moment(dateString).format('DD.MM.YYYY HH:mm')
        },
        async getReperatur(data){
            this.timelineEvents=[];
            if (data === 'delete'){
                this.reperatur = await this.dataService.getReperaturByProuktIdLatest(this.$route.params.id);
                this.reperaturObj = this.reperatur[0];
                console.log(this.reperaturObj)
            } else {
                this.reperaturObj = data;
            }

            this.timelineEvents[0] = { status: 'Offen', date: this.formatDate(this.reperaturObj.TS_EINGANG), icon: "pi pi-shopping-cart", color: '#E91E63', description: this.reperaturObj.EINGANGSMELDUNG, defect: this.reperaturObj.MAENGEL };
            if (this.reperaturObj.ANGENOMMEN){
                this.timelineEvents[1] = { status: 'In Bearbeitung', date: this.formatDate(this.reperaturObj.TS_ANNAHME), icon: "pi pi-cog", color: '#FB8C00', description: this.reperaturObj.ANNAHMEMELDUNG };
            }
            if (this.reperaturObj.ABGESCHLOSSEN){
                this.timelineEvents[2] = { status: 'Abgeschlossen', date: this.formatDate(this.reperaturObj.TS_ABSCHLUSS), icon: "pi pi-check-square", color: '#0097A7', description: this.reperaturObj.ABSCHLUSSMELDUNG, final:this.reperaturObj.ABSCHLUSS };
            }
        },
        async calculateLebensZyklus(){
            this.startJahr = this.produktObj.BAUJAHR;
            this.endJahr = this.produktObj.ABLAUFJAHR;
            this.totalJahr = this.endJahr - this.startJahr;
            let currentYear = new Date();
            currentYear = currentYear.getFullYear();
            this.gebrauchJahr = currentYear - this.startJahr;
            this.prozentJahr = (100 * this.gebrauchJahr) / this.totalJahr;
        },
        async onInfo(){
            this.infoDialog = true;
        },
        async onStandort(){
            this.standortDialog = true;
        },
        async onPrint(){
            // store.commit('storeRowClass', 'layout-invoice-content');
            //
            // setTimeout(() => {
            //     window.print()
            // }, 100);
            window.print();

        },
        async onQr(){
            this.qrDialog=true;
        }


    },
    computed: {
        store() {
            return store
        },
        isRTL() {
            return this.$appState.RTL;
        }
    }
}
</script>

<style scoped>

@media print {

    /*body * {*/
    /*    visibility: hidden;*/
    /*}*/

    /*@page {*/
    /*    size: landscape;*/
    /*}*/

    .no-print, .no-print *
    {
        display: none !important;
    }

    .rotate-90-container {
        transform: rotate(90deg);
        transform-origin: top left;
        white-space: nowrap;
        position: absolute;
        top: 100%; /* Adjust this value based on your layout */
        left: 0;
    }

}

</style>
